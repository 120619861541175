<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <a @click="$router.go(-1)" class="link__back">
            <span class="link__back--img">
              <img src="../../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>
      <div class="section__line">
        <div class="section__bg section--bank__info section--bank__data">
          <div class="agreement__info--block">
            <div class="agreement__info--item">
              <div class="agreement__info--icon">
                <img src="../../../assets/img/user-icon.svg" />
              </div>
              <div class="agreement__info--right">
                <div class="agreement__info--type">Инициатор</div>
                <div class="agreement__info--title">
                  {{ GET_USER_DATA.common_name + " " + GET_USER_DATA.last_name }}
                </div>
              </div>
            </div>
            <div class="agreement__info--item agreement__info--status">
              <div class="agreement__info--icon">
                <img src="../../../assets/img/status-icon.svg" />
              </div>
              <div class="agreement__info--right">
                <div class="agreement__info--type">Статус</div>
                <div class="agreement__info--title">Создание</div>
              </div>
            </div>
            <div class="agreement__info--item">
              <div class="agreement__info--icon">
                <img src="../../../assets/img/file-icon.svg" />
              </div>
              <div class="agreement__info--right">
                <div class="agreement__info--type">Вид договора</div>
                <div class="agreement__info--title">Приложение</div>
              </div>
            </div>
          </div>

          <form @submit.prevent="createContract">
            <hr class="line__hr" />

            <div class="agreement__line">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <div class="agreement__item--title">БИК</div>
                    </div>
                    <div class="col-lg-9">
                      <div class="agreement__item--input">
                        <input
                          type="text"
                          required
                          v-model="mspd.bik"
                          name=""
                          placeholder="БИК"
                          value=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="agreement__line">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <div class="agreement__item--title">счет KZ</div>
                    </div>
                    <div class="col-lg-9">
                      <div class="agreement__item--input">
                        <input
                          type="text"
                          required
                          v-model="mspd.check_number"
                          placeholder="счет KZ"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="agreement__line">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row align-items-center">
                    <div class="col-lg-3">
                      <div class="agreement__item--title">Для</div>
                    </div>
                    <div class="col-lg-9">
                      <div class="agreement__item--input">
                        <input
                          type="text"
                          required
                          v-model="mspd.client_for"
                          name=""
                          placeholder="Для"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="line__hr" />

            <div class="row">
              <div class="col-lg-9 offset-lg-3">
                <v-file-input
                  v-model="images"
                  placeholder=""
                  label="Выберите файл"
                  multiple
                  prepend-icon="mdi-paperclip"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <button type="submit" class="form__button">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
                      fill="white"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
                      fill="white"
                    ></path>
                  </svg>
                  Отправить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["contract_title", "document_id", "type", "attach_id", "attachment"],
  data() {
    return {
      showToggle: false,
      messageColor: null,
      message: "",
      images: [],
      mspd: {
        bik: "",
        client_for: "",
        check_number: "",
      },
      messages: [],
    };
  },
  created() {},
  methods: {
    createContract() {
      let contractForm = new FormData();
      contractForm.append("bik", this.mspd.bik);
      contractForm.append("client_for", this.mspd.client_for);
      contractForm.append("check_number", this.mspd.check_number);
      contractForm.append("attachment_id", this.$route.params.attachment_id);
      contractForm.append("document_id", this.$route.params.id);
      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(
          this.$API_URL +
            this.$API_VERSION +
            "user/documents/mspd/" +
            this.$route.params.id +
            "/format",
          contractForm,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$toast.open({
            message: "Успешно сохранено",
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.$router.go(-1);
        })
        .catch((error) => {
          let errors = error.response.data.errors;
          console.log(errors);

          this.messages = [];
          for (let key in error.response.data.errors) {
            this.messages[key] = error.response.data.errors[key].pop();
          }
        });
    },
    getAttachment() {
      this.$axios
        .get(
          this.$API_URL +
            this.$API_VERSION +
            "user/attachment?id=" +
            this.$route.params.attachment_id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.mspd = response.data.fields;
        })
        .catch((error) => {
          console.log(error.response);

          let errors = error.response.data.errors;
          console.log(errors);

          this.$toast.open({
            message: "Заполните все поля",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
  },
  mounted() {
    this.getAttachment();
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
